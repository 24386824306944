(function () {
    'use strict';

    /* eslint-disable no-invalid-this */
    ebates.stats = ebates.stats || {};
    ebates.stats.taggedModuleInit = scrollerEl => {
        /**
         *  Improved tagging functionality
         *  Supports "topic/collection/module - tile/item" structure
         *  topic/collection/module is identified via "data-is-topic" tag
         *  Tile/item "data-view-evt" tracking will be initialized only after its parent "data-view-evt" is fired
         *  assumption: View Tile event can't be fired before its parent View Topic/Module one
         *  Reuses unified data tagging schema used by click events - thus reducing tag bloating
         *  Work in progress - initTileViewTracking() needs to be implemented (for both horizontal scrolling and non scrolling modules)
         *  IMPORTANT - this should be refactored to use Intersection Observer, as soon as we retire IE 11
         */
        {
            const scrollThrottle = 500,
                modules = scrollerEl.querySelectorAll('[data-view-evt][data-is-topic]'),  // data-is-topic - to differentiate between tile and topic
                /**
                 * @description recursively (if needed) travel up the DOM tree to find the prop data attrs
                 * @param el
                 * @param prop
                 * @returns {*|string|null}
                 */
                getPropValue = (el, prop) => el.dataset[prop] || ((el.parentNode && el.parentNode !== document) ? getPropValue(el.parentNode, prop) : null),
                /**
                 * @description construct and return event object; may be used for different type of events, hence the type and sig params
                 * @param el
                 * @param eventType
                 * @param eventSignature
                 * @returns {*}
                 */
                getEventData = (el, eventType, eventSignature) => {
                    const propNames = eventSignature && eventSignature.split(','),
                        eventData = {
                            eventType,
                            eventProps: {}
                        };

                    return propNames ? propNames.reduce(
                        (data, fieldName) => {
                            switch (fieldName) {
                            // - translate '_eds' to the addEdsPropSet flag, which in turn will signal the SDK to add the EDS user data set to the event
                            case '_eds':
                                data.addEdsPropSet = true;
                                break;
                            case 'topic_position': {
                                // If there is no already provided value, topic_position will be calculated programmatically
                                const topicPositionVal = getPropValue(el, 'topic_position');

                                if (!topicPositionVal && 'isTopic' in el.dataset) {
                                    const topics = document.querySelectorAll('[data-is-topic]'),
                                        topicIndex = Array.prototype.indexOf.call(topics, el);

                                    if (topicIndex !== -1) {
                                        data.eventProps.topic_position = topicIndex;
                                        el.setAttribute('data-topic_position', topicIndex);
                                    }
                                } else {
                                    data.eventProps.topic_position = topicPositionVal;
                                }
                                break;
                            }
                            case 'entity_array_name': {
                                // If there is no already provided value, entity names will be aggregated from tile click tags
                                const entityArrayNameVal = getPropValue(el, 'entity_array_name');

                                if (!entityArrayNameVal) {
                                    const entities = el.querySelectorAll('[data-tile_name]');

                                    if (entities.length) {
                                        data.eventProps.entity_array_name = Array.prototype.map.bind(entities, entity => entity.dataset.tile_name)();
                                    }
                                } else {
                                    data.eventProps.entity_array_name = entityArrayNameVal;
                                }
                                break;
                            }
                            case 'entity_array_id': {
                                // If there is no already provided value, entity ids will be aggregated from tile click tags
                                // First we check is these are coupons, then fallback to stores
                                const entityArrayIdVal = getPropValue(el, 'entity_array_id');

                                if (!entityArrayIdVal) {
                                    const couponEntities = el.querySelectorAll('[data-coupon_id]'),
                                        entities = couponEntities.length ? couponEntities : el.querySelectorAll('[data-store_id]');

                                    if (entities.length) {
                                        const datapoint = couponEntities.length ? 'coupon_id' : 'store_id';

                                        data.eventProps.entity_array_id = Array.prototype.map.bind(entities, ent => ent.dataset[datapoint])();
                                    }
                                }  else {
                                    data.eventProps.entity_array_id = entityArrayIdVal;
                                }
                                break;
                            }
                            // populate remaining props with matching tag values from the dataset
                            default: {
                                const value = getPropValue(el, fieldName);

                                value !== null && (data.eventProps[fieldName] = value);
                            }
                            }
                            return data;
                        },
                        eventData
                    ) : eventData;
                },
                initModuleTileViewTracking = module => {
                    const viewsUseClickProps = 'viewsUseClickProps' in module.dataset,       // data-views-use-click-props
                        tiles = module.querySelectorAll(viewsUseClickProps ? '[data-evt="Click Tile"]' : '[data-view-evt]'),
                        scroller = module.querySelector('[data-is-scroller]'),
                        container = scroller || document.getElementById('scrolling-container') || document,
                        initTileViewTracking = tile => {
                            if (!tile.classList.contains('tile-init-view')){
                                const eventSignature = tile.dataset[viewsUseClickProps ? 'evtSig' : 'viewSig'],  // data-view-sig
                                    eventType = viewsUseClickProps ? 'View Tile' : tile.dataset.viewEvt, // data-view-evt
                                    trackTileView = () => {
                                        if (ebates.UI.isInView(tile)) {
                                            const viewEventData = getEventData(tile, eventType, eventSignature),
                                                viewEvent = new CustomEvent('stats-event', { detail: { map: { amp: viewEventData } } });

                                            tile.viewScrollListenerId && ebates.UI.scroll.off(tile.viewScrollListenerId);   // done - turn off the listener
                                            document.dispatchEvent(viewEvent);
                                        }
                                    };

                                // if tile is in view - fire view event, else init its scroll listener
                                ebates.UI.isInView(tile) ?
                                    trackTileView() :
                                    tile.viewScrollListenerId = ebates.UI.scroll.add(trackTileView, container, scrollThrottle);

                                tile.classList.add('tile-init-view');
                            }
                        };

                    tiles.length && setTimeout(Array.prototype.map.bind(tiles, initTileViewTracking), 0);

                    // fire one module scroll event
                    if (scroller && scroller.dataset.scrollEvt) {    // data-scroll-evt
                        const fireOneScrollEvent = function () {
                            const eventSignature = this.dataset.scrollSig,  // data-scroll-sig
                                eventType = this.dataset.scrollEvt,   // data-scroll-evt
                                viewEventData = getEventData(this, eventType, eventSignature),
                                viewEvent = new CustomEvent('stats-event', { detail: { map: { amp: viewEventData } } });

                            this.scrollEventListenerId && ebates.UI.scroll.off(this.scrollEventListenerId);   // done - turn off the listener
                            document.dispatchEvent(viewEvent);
                        };

                        scroller.scrollEventListenerId = ebates.UI.scroll.add(fireOneScrollEvent.bind(scroller), scroller);
                    }
                },
                initModuleViewTracking = module => {
                    if (!module.classList.contains('module-init-view')){
                        const container = document.getElementById('scrolling-container') || document,
                            eventSignature = module.dataset.viewSig,  // data-view-sig
                            eventType = module.dataset.viewEvt, // data-view-evt
                            /**
                             * @desc used to retrive data-view-fract; to be used for bigger than viewport modules. ex.: data-view-fract="0.05" - 5%
                             * @type {{fraction: number}}
                             */
                            viewOpts = module.dataset.viewFract ? { fraction: +module.dataset.viewFract } : {},
                            trackModuleView = () => {
                                if (ebates.UI.isInView(module, viewOpts)) {
                                    const viewEventData = getEventData(module, eventType, eventSignature),
                                        viewEvent = new CustomEvent('stats-event', { detail: { map: { amp: viewEventData } } });

                                    module.viewScrollListenerId && ebates.UI.scroll.off(module.viewScrollListenerId);   // done - turn off the listener
                                    document.dispatchEvent(viewEvent);
                                    initModuleTileViewTracking(module);
                                }
                            };

                        // if module is in view - fire view event, else init its scroll listener
                        ebates.UI.isInView(module, viewOpts) ?
                            trackModuleView() :
                            module.viewScrollListenerId = ebates.UI.scroll.add(trackModuleView, container, scrollThrottle);

                        module.classList.add('module-init-view');
                    }
                };

            modules.length && setTimeout(Array.prototype.map.bind(modules, initModuleViewTracking), 0);
        }

        /**
         * Legacy tagging; for backward compat; eventually to be deprecated and removed
         */
        {
            if (scrollerEl && !scrollerEl.classList.contains('scroller-initialized')) {
                const $doc = $(document),
                    oneScrollEventElements = scrollerEl.querySelectorAll('[data-send-one-scroll-event]'),
                    viewEventElements = scrollerEl.querySelectorAll('[data-send-view]'),
                    initialCap = str => str && str.replace(str[0], str[0].toUpperCase()),
                    eventDataFactory = {
                        template: {
                            data: ['send', 'event'],
                            map: {
                                external: {},
                                amp: { eventProps: {} }
                            }
                        },
                        populate: {
                            ga: function (eventClass, template) {
                                this.dataset[eventClass + 'Ga'].split(',').map(function (datum) {
                                    template.data.push(datum);
                                });
                            },
                            external: function (eventClass, template) {
                                this.dataset[eventClass + 'Signature'].split(',').map(fieldName => {
                                    template.map.external[fieldName] = this.dataset[eventClass + initialCap(fieldName)];
                                });
                            },
                            amp: function (eventClass, template) {
                                const signatureDataName = `${eventClass}AmpSignature`,
                                    ampEventSignature = typeof this.dataset[signatureDataName] !== undefined && this.dataset[signatureDataName];

                                template.map.amp.eventType = this.dataset[eventClass + 'AmpType'];
                                if (ampEventSignature) {
                                    const propNames = ampEventSignature.split(',');

                                    propNames.map(fieldName => {
                                        switch (fieldName) {
                                        // - translate '_eds' to the addEdsPropSet flag, which in turn will signal the SDK to add the EDS user data set to the event
                                        case '_eds':
                                            template.map.amp.addEdsPropSet = true;
                                            break;
                                            // populate remaining props with matching tag values from the dataset
                                        default:
                                            template.map.amp.eventProps[fieldName] = this.dataset[eventClass + initialCap(fieldName)];
                                        }
                                    });
                                }
                            }
                        }
                    },
                    fireOneScrollEvent = function () {
                        const thisDataset = this.dataset,
                            self = this,
                            eventDestinations = (thisDataset['sendOneScrollEvent'] || '').split(','),
                            eventData = JSON.parse(JSON.stringify(eventDataFactory.template));

                        // populate event data
                        eventDestinations.map(function (eventDestination) {
                            eventDataFactory.populate[eventDestination] && eventDataFactory.populate[eventDestination].bind(self)('scrollEvent', eventData);
                        });
                        // send the event
                        $doc.trigger('stats', eventData);
                        // done - turn off the listener
                        ebates.UI.scroll.off(this.oneScrollListenerId);
                    },
                    fireOneInViewEvent = function () {
                        if (ebates.UI.isInView(this) && this.viewEventData) {
                            ebates.UI.scroll.off(this.viewScrollListenerId);   // done - turn off the listener
                            $doc.trigger('stats', this.viewEventData);
                        }
                    },
                    addScrollListeners = scroller => {
                        if (!scroller.classList.contains('module-init-scroll')){
                            const scrollViewEventTargets = scroller.querySelectorAll('[data-scroll-view-event]'),
                                initScrollViewEventTarget = scrollViewEventTarget => {
                                    const eventDestinations = (scrollViewEventTarget.dataset['scrollViewEvent'] || '').split(','),  // "ga,amp" -> ['ga', 'amp']
                                        eventData = JSON.parse(JSON.stringify(eventDataFactory.template));  // clone event template

                                    // populate event data (eventData)
                                    eventDestinations.map(eventDestination => {
                                        eventDataFactory.populate[eventDestination] &&
                                        eventDataFactory.populate[eventDestination].bind(scrollViewEventTarget)('scrollViewEvent', eventData);
                                    });

                                    // attach event data to the element
                                    scrollViewEventTarget.viewEventData = eventData;

                                    // set scroll listener on the "scroller" (note: scroll event listener on the document is not set here yet!)
                                    scrollViewEventTarget.viewScrollListenerId = ebates.UI.scroll.add(fireOneInViewEvent.bind(scrollViewEventTarget), scroller);

                                    // check if the element is already visible and if so, fire the event
                                    fireOneInViewEvent.bind(scrollViewEventTarget)();
                                };

                            scrollViewEventTargets.length && Array.prototype.map.call(scrollViewEventTargets, initScrollViewEventTarget);
                            scroller.oneScrollListenerId = ebates.UI.scroll.add(fireOneScrollEvent.bind(scroller), scroller);

                            scroller.classList.add('module-init-scroll');
                        }
                    },
                    initViewEvents = module => {
                        if (!module.classList.contains('module-init-view')){
                            const eventData = JSON.parse(JSON.stringify(eventDataFactory.template)),
                                eventDestinations = (module.dataset['sendView'] || '').split(','),
                                sendViewEvent = () => {
                                    if (ebates.UI.isInView(module)) {
                                        module.viewScrollListenerId && ebates.UI.scroll.off(module.viewScrollListenerId);   // done - turn off the listener
                                        $doc.trigger('stats', eventData);
                                    }
                                };

                            eventDestinations.map(function (eventDestination) {
                                eventDataFactory.populate[eventDestination] && eventDataFactory.populate[eventDestination].bind(module)('view', eventData);
                            });

                            // if module is in view - fire view events, else init scroll listener
                            ebates.UI.isInView(module) ? sendViewEvent() : module.viewScrollListenerId = ebates.UI.scroll.add(sendViewEvent);

                            module.classList.add('module-init-view');
                        }
                    };

                // set event listeners on all scrollers; off main thread and to avoid firing scroll on pre-scrolled container
                oneScrollEventElements.length && setTimeout(Array.prototype.map.bind(oneScrollEventElements, addScrollListeners), 0);
                viewEventElements.length && setTimeout(Array.prototype.map.bind(viewEventElements, initViewEvents), 0);
            }
        }
    };

    ebates.stats.taggedScrollerInit = id => {
        const scrollerEl = document.getElementById(id);

        ebates.stats.taggedModuleInit(scrollerEl);
    };

    /* globals lyftCB */

    /** For "View" and "Scroll" Amplitude events in cases when the module is there on DOM-ready */
    window.addEventListener('DOMContentLoaded', ebates.stats.taggedModuleInit.bind(ebates.stats, document.body));

    /** For "View" and "Scroll" Amplitude events in cases when the module is loaded via ajax */
    document.addEventListener('ajax-module-loaded', e => ebates.stats.taggedModuleInit(e.detail.el));

    (($body, $doc) => {
        const scrollingContainer = document.getElementById('scrolling-container'),
            $scrollingContainer = $(scrollingContainer),
            scrollEvent = new Event('scroll');

        if (!window._EbatesMobile) {
            _EbatesMobile = {};
        }
        ebates.mw = ebates.mw || {};
        ebates.mw.isIOS = /(iPad|iPhone|iPod)/.test(navigator.userAgent),
        ebates.mw.UI = ebates.mw.UI || {};
        ebates.mw.UI.scrollingContainer = $scrollingContainer;
        ebates.mw.UI.modals = {
            setup() {
                // global iOS hacks for Bootstrap modals;
                const bootstrapSafeDelay = 400;

                $doc.on('ui-reset', () => scrollingContainer.dispatchEvent(scrollEvent));

                $doc.on('show.bs.modal', function(e) {
                    const modalClassList = e.target.classList,
                        fullScreenModalMargin = 100;

                    // if Branch banner is active and if the modal is not full-screen trigger its change event so that the header could readjust to it
                    if (document.body.classList.contains('branch-banner-is-active') && !e.target.classList.contains('full-screen-modal')) {
                        $doc.trigger('branch-change');
                    }
                    // disable background scrolling
                    ebates.mw.isIOS && document.body.classList.add('noscroll');

                    // iOS modal background fix
                    if (e.target && !modalClassList.contains('ios-modal-moved')) {
                        document.body.appendChild(e.target);
                        modalClassList.add('ios-modal-moved');
                    }

                    // iOS virtual keyboard hack; to apply it add ios-keyboard-hack class to the modal
                    if (modalClassList && modalClassList.contains('ios-keyboard-hack')) {
                        modalClassList.add('absolute');
                        setTimeout(() => {
                            const isModalExactViewport = modalClassList.contains('exact-viewport');

                            isModalExactViewport && document.querySelector('.modal-backdrop').classList.add('exact-viewport-backdrop');
                            e.target.style.top = `-${modalClassList.contains('full-screen-modal') && !isModalExactViewport ? fullScreenModalMargin : 0}px`;
                        }, bootstrapSafeDelay);
                    }
                });

                // Trigger UI reset so that visible through the backdrop sticky tabs could readjust their positions, or after the modal is closed
                $doc.on('shown.bs.modal', e => !e.target.classList.contains('full-screen-modal') && $doc.trigger('ui-reset'));
                $doc.on('hidden.bs.modal', e => !e.target.classList.contains('full-screen-modal') && $doc.trigger('ui-reset'));

                $doc.on('hide.bs.modal', function(e) {
                    document.body.classList.remove('noscroll');
                    e.target.classList.remove('absolute');
                    // if Branch banner is active and if the modal is not full-screen trigger its change event so that the header could readjust to it
                    if (document.body.classList.contains('branch-banner-is-active') && !e.target.classList.contains('full-screen-modal')) {
                        $doc.trigger('branch-change');
                    }
                });
            }
        };

        ebates.mw.UI.lazy = {
            setup() {
                // uncookied homepage SU form moves a scroller out of the view - no need to init that one, yet
                // trial-and-error heuristic value derived using Safari as the baseline
                const horizontalLazyLoadInitDelay = 600;

                // Lazily load the lazyload script itself; once loaded the $.fn.lazyload placeholder will be overwritten with lazyload proper
                $.fn.lazyload = function() {
                    ebates.js.require(ebates.src.mw.lazy, () => {
                        jQuery.fn.lazyload.apply(this, arguments);
                        setTimeout(() => ebates.mw.UI.scrollingContainer.trigger('scroll'), 0); // trigger scroll once the plugin is loaded
                    });
                    return this;
                };

                // General vertical lazyload init
                $('img.lazy').lazyload({ failure_limit: 20 });
                ebates.mw.UI.scrollingContainer.trigger('scroll');

                // Horizontal scroller setup
                // init only when module itself scrolls into viewport
                // this is getting a bit messy - time to look for a more modern lazy loader
                // timeout - not to block the main thread
                // scroll-carousel - this classname implies loosely coupled but global functionality we need to remember!
                setTimeout(() => {
                    const horizontalScrollers = document.querySelectorAll('.scroll-carousel'),
                        initHorizontalLazyload = module => {
                            $('img.lazy-horizontal', module).lazyload({
                                container: module,
                                threshold: module.dataset.lazyThreshold || 0
                            });
                        },
                        lazyInitScrollListener = function() {
                            if (ebates.UI.isInView(this)) {
                                initHorizontalLazyload(this);
                                ebates.UI.scroll.off(this.scrollListenerId);
                            }
                        };

                    // if scrolling module is visible init it, else wait until it becomes visible
                    Array.from(horizontalScrollers).map( scroller => {
                        if (ebates.UI.isInView(scroller)) {
                            initHorizontalLazyload(scroller);
                        } else {
                            scroller.scrollListenerId = ebates.UI.scroll.add(lazyInitScrollListener.bind(scroller));
                        }
                    } );

                    horizontalScrollers.length && $doc.trigger('scroll-carousel-loaded');
                }, horizontalLazyLoadInitDelay);
            }
        };

        if (!window._EbatesMobile.validation) {
            _EbatesMobile.validation = {};
        }

        _EbatesMobile.pageURL = window.location.pathname;

        _EbatesMobile.toggleDrawer = function (drawerEl, triggerEl, position) {

            var drawer = $(drawerEl);
            var trigger = $(triggerEl);

            if ( (typeof position === 'undefined') && drawer.hasClass('closed') ) {
                position = 'open';
            } else {
                position = 'close';
            }

            if ( position === 'open' && drawer.hasClass('closed') ) {
                drawer.slideDown(200);
                drawer.removeClass('closed').addClass('open');
                if (trigger) {
                    setTimeout(function(){
                        trigger.addClass('active');
                    }, 200);
                }
            } else if ( position === 'close' && drawer.hasClass('open') ) {
                drawer.slideUp(200);
                drawer.removeClass('open').addClass('closed');
                if (trigger) {
                    setTimeout(function(){
                        trigger.removeClass('active');
                    }, 200);
                }
            }

            return position;
        };

        _EbatesMobile.toggleNoAddressDrawer = function (position) {
            _EbatesMobile.toggleDrawer('.noPrimaryAddress_box', '', position);
        };

        _EbatesMobile.toggleCouponCodeDrawer = function(el_trigger, position) {

            var highlight_box = $(el_trigger).closest('.store-highlight-box');
            if (position === 'close') {
                $(highlight_box).find('.btn-getcode').show();
                $(highlight_box).find('.btn-hidecode').hide();
                $(highlight_box).find('.couponCode_box').slideUp(200);
            } else {
                $(highlight_box).find('.btn-getcode').hide();
                $(highlight_box).find('.btn-hidecode').show();
                $(highlight_box).find('.couponCode_box').slideDown(200);
            }

        };

        _EbatesMobile.openCouponCodeDrawerAndShop = function(el_trigger) {
            var highlight_box = $(el_trigger).closest('.store-highlight-box'),
                special_id = highlight_box.parent().attr('id'),
                bkgd_link = highlight_box.find('.btn-shopnow').attr('href'),
                tab_link;
            special_id = special_id.indexOf('special-') > -1 ? special_id.substring(8) : '';
            tab_link = _EbatesMobile.updateQueryStringParameter(window.location.toString(), 'special', special_id);
            $(highlight_box).find('.btn-getcode-shop').hide();
            $(highlight_box).find('.couponCode_box').slideDown(200);
            _EbatesMobile.redirectURL(tab_link, true);
            _EbatesMobile.redirectURL(bkgd_link);
        };

        _EbatesMobile.displayError = function(errorMsg, container, dismissDelay, doNotScrollBackground) {
            if (typeof errorMsg !== 'undefined' && errorMsg != null && errorMsg !== '') {
                const container = container !== 'undefined' ? $(container) : $body;

                container.find('.alertRow').hide();
                container.find('.alertError').html(errorMsg);
                container.find('.alertErrorRow').fadeOut();
                container.find('.alertErrorRow').fadeIn();

                if (typeof doNotScrollBackground === 'undefined' || !doNotScrollBackground) {
                    if (container.find('.alertErrorRow').length) {
                        $scrollingContainer.animate({scrollTop: container.find('.alertErrorRow').offset().top - 45}, 'fast');
                    }
                }

                if (typeof dismissDelay !== 'undefined' && dismissDelay) {

                    if (dismissDelay == true) {
                        dismissDelay = 5000;
                    }

                    setTimeout(function() {
                        container.find('.alertErrorRow').fadeOut();
                    }, dismissDelay);
                }
            }
        };

        _EbatesMobile.displaySuccess = function(successMsg, container, dismissDelay) {
            if (typeof successMsg !== 'undefined' && successMsg != null && successMsg != '') {

                if (typeof container !== 'undefined') {
                    var container = $(container);
                } else {
                    var container = $body;
                }

                container.find('.alertRow').hide();
                container.find('.alertSuccess').html(successMsg);
                container.find('.alertSuccessRow').fadeOut();
                container.find('.alertSuccessRow').fadeIn();
                $scrollingContainer.animate({scrollTop: container.find('.alertSuccessRow').offset().top - 20}, 'fast');

                if (typeof dismissDelay !== 'undefined') {

                    if (dismissDelay == true) {
                        dismissDelay = 5000;
                    }

                    setTimeout(function() {
                        container.find('.alertSuccessRow').fadeOut();
                    }, dismissDelay);
                }
            }
        };

        _EbatesMobile.truncateToggle = function(obj) {
            var target = obj.attr('data-target');
            var extraTarget = obj.attr('data-extra-target');


            if (target) {

                if ( obj.hasClass('active') ) {
                    if (obj.attr('data-flag'))
                        obj.html('Special Conditions <i class="fa fa-chevron-down"></i>');
                    else {
                        if (obj.hasClass('trunc-read'))
                            obj.html('See More');
                        else
                            obj.html('More <i class="fa fa-chevron-down"></i>');
                    }
                    obj.removeClass('active');
                    $(target).find('.full-text').slideUp(200);
                    $(target).find('.trunc-text').show();
                    if (extraTarget) {
                        $(extraTarget).show();
                    }
                } else {
                    obj.addClass('active');
                    obj.html('See Less');
                    $(target).find('.trunc-text').hide();
                    $(target).find('.full-text').slideDown(200);
                    if (extraTarget) {
                        $(extraTarget).hide();
                    }
                }
            }

        };

        _EbatesMobile.redirectURL = function(url, newWindow) {
            if (url) {
                if (typeof newWindow !== 'undefined' && newWindow == true) {
                    window.open(url, '_blank');
                } else {
                    window.location = url;
                }
            }
        };

        _EbatesMobile.secondaryTabs = function() {
            $('.tab-secondary-nav').on('click', function() {
                var target = $(this).attr('data-tab');

                if (target && !$(this).hasClass('active')) {
                    $('.tab-secondary').hide();
                    $('.tab-secondary[data-tab="' + target + '"]').show();
                    $('.tab-secondary-nav').removeClass('active');
                    $(this).addClass('active');
                }

            });
        };

        _EbatesMobile.showDialogAlert = function(options) {

            var defaults = {
                message: '',
                closeButton: '',
                title: '',
                video: false
            };

            if (typeof options === 'object') {
                var options = $.extend(defaults, options);
            } else {
                var options = $.extend(defaults, {message: options});
            }

            if (typeof options.message !== 'undefined' && options.message.length > 0) {
                if (options.title.trim().length > 0) {
                    var modalHeader = '<div class="modal-header full-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button><h4 class="modal-title">' + options.title + '</h4></div>';
                } else {
                    var modalHeader = '<div class="modal-header min-header"><button type="button" class="close ht-46" data-dismiss="modal" aria-hidden="true" style="opacity:1"><span class="fa-times f-16 f-gry-c pointer"></span></button></div>';
                }

                if (options.closeButton.trim() == '') {
                    var modalFooter = '';
                } else {
                    var modalFooter = '<div class="modal-footer"><button class="btn btn-success" data-dismiss="modal">' + options.closeButton + '</button></div>';
                }
                if ($('#dialog-alert-modal').length > 0) {
                    $('#dialog-alert-modal').remove();
                }
                if (options.video === true) {
                    var modal = '<div class="modal fade modal-video" id="dialog-alert-modal"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><button type="button" class="font-light text-shadow-dark-xs close" data-dismiss="modal" aria-hidden="true">&times;</button></div><div class="modal-body nopadding"><div class="row"><div class="col-xs-12 center modal-message"><div class="embed-container"><iframe id="ytvideo" src="' + options.message + '" frameborder="0" allowfullscreen></iframe></div></div></div><script>$(".modal-video").on("hidden.bs.modal", function(){$("#ytvideo").remove();});</script></div>';
                } else {
                    var modal = '<div class="modal fade" id="dialog-alert-modal"><div class="modal-dialog dialog-centered"><div class="modal-content">' + modalHeader + '<div class="modal-body"><div class="row rowpad"><div class="col-xs-12 center modal-message">' + options.message + '</div></div></div>';
                }

                modal += modalFooter;
                $body.append(modal);
                $('#dialog-alert-modal').modal('show');

            }
        };

        _EbatesMobile.hideDialogAlert = function(elem) {
            $(elem).modal('hide');
        };

        _EbatesMobile.sessionExpiredRedirect = function(url) {
            url = typeof url !== 'undefined' ? url : '/myaccount/verify.htm';
            _EbatesMobile.displayError('Your session has expired, for security, you will be redirected to sign in again.');
            setTimeout(function() {
                window.location.href = url;
            }, 1500);
        };


        _EbatesMobile.anchorScrollTo = function(anchorName) {
            if (typeof anchorName !== 'undefined' && anchorName != null && anchorName != '') {
                var anchorObj = $('a[name="' + anchorName + '"]');
                $scrollingContainer.animate({scrollTop: anchorObj.offset().top - 20}, 'normal');
            }
        };

        _EbatesMobile.scrollTo = function(elementName, padTop){
            if (typeof elementName !== 'undefined' && elementName != null && elementName != ''){
                var elem = $(elementName),
                    top;
                top = elem.size() > 0 ? elem.offset().top : 0;
                top -= $('.header').height();
                top -= typeof padTop !== 'undefined' && padTop > 0 ? padTop : 0;
                $scrollingContainer.animate({scrollTop: top}, 'fast');
            }
        };

        _EbatesMobile.setCookie = function(sourcename, value, expdays) {

            var expires = '';
            if (expdays) {
                var exdate = new Date();
                exdate.setTime(exdate.getTime() + (expdays * 24 * 60 * 60 * 1000));
                expires = '; expires=' + exdate.toGMTString();
                exdate.setDate(exdate.getDate() + expdays);
            }
            document.cookie = sourcename + '=' + escape(value) + expires + '; path=/';
        };

        _EbatesMobile.getCookie = function(key) {
            var result;
            return (result = new RegExp('(?:^|; )' + encodeURIComponent(key) + '=([^;]*)').exec(document.cookie)) ? (result[1]) : null;
        };

        _EbatesMobile.deleteCookie = function(key) {
            document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        };


        _EbatesMobile.getParameterByName = function(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
                results = regex.exec(location.search);
            return results == null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        };

        _EbatesMobile.updateQueryStringParameter = function(uri, key, value) {
            var i = uri.indexOf('#'),
                hash = i === -1 ? ''  : uri.substr(i),
                uri = i === -1 ? uri : uri.substr(0, i),
                re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i'),
                separator = uri.indexOf('?') !== -1 ? '&' : '?';
            if (uri.match(re)){
                uri = uri.replace(re, '$1' + key + '=' + value + '$2');
            } else {
                uri = uri + separator + key + '=' + value;
            }
            return uri + hash;
        };

        _EbatesMobile.trackCarousel = function(id, page_name){
            var carousel_selector = id + '.carousel',
                carousel_variation = $(carousel_selector).data('variation') || '',
                control = {clicked: false, swiped: false, direction: ''};
            $(carousel_selector).on('slid.bs.carousel', function (e){
                var action = carousel_variation + ' - ',
                    label = $(this).find('.item.active').find('.store-bg-banner').attr('data-backgroundimg') || '';
                action += control.clicked ? control.direction === 'right' ? 'next' : 'previous' : control.swiped ? control.direction === 'right' ? 'swiped next' : 'swiped previous' : 'auto' ;
                $(document).trigger('stats', {data: ['send', 'event', page_name, action, label]});
                control.clicked = false;
                control.swiped = false;
            });
            $(carousel_selector + ' .left.carousel-control').on('click', function (e) {
                control.direction = 'left';
                control.clicked = true;
            });
            $(carousel_selector + ' .right.carousel-control').on('click', function (e) {
                control.direction = 'right';
                control.clicked = true;
            });
            $(document).on('swipeleft', carousel_selector, function() {
                control.direction = 'right';
                control.swiped = true;
            }).on('swiperight', carousel_selector, function() {
                control.direction = 'left';
                control.swiped = true;
            });
        };

        _EbatesMobile.createStoreEntity = function(this_data, is_stacked, is_fav, is_not_lazy, dca){
            const style_mod = $('#ajax-modules'),
                obj = style_mod.find('#ajax-fav-store').clone().removeAttr('id'),
                tileStoreLinks = obj.find('.href-link'),
                tileFaveButton = obj.find('.fav-btn'),
                ampTags = {
                    'data-amp-store_name': this_data.name,
                    'data-amp-store_id': this_data.merchantId
                };

            // add Amplitude tags; .attr user for perf. reasons; same for links and fave button
            tileStoreLinks.attr(ampTags);
            tileFaveButton.attr(ampTags);

            // Lyft exception
            if (this_data.merchantId === '13692'){
                this_data.cb = lyftCB ? lyftCB + ' Cash Back' : this_data.cb;
            }

            !is_stacked ? obj.removeClass('store-highlight-box-stacked') : false;
            dca = dca || 'Click favorite stores';
            if (!is_fav){
                tileFaveButton.removeClass('active');
                obj.find('.fa.fa-heart').removeClass('fa-heart').addClass('fa-heart-o');
            } else {
                tileFaveButton.attr('data-amp-evt-type', 'Remove Favorite');
            }
            tileFaveButton.attr('data-merch', this_data.merchantId);
            tileStoreLinks.attr({'href': this_data.link, 'data-navigation-id': this_data.sourceId, 'data-click-action': dca});
            if (this_data.img !== ''){
                obj.find('img').attr('data-original', this_data.img).attr('alt', this_data.alt).attr('title', this_data.alt);
            } else {
                obj.find('img').addClass('hide');
                obj.find('.store-text').text(this_data.name).removeClass('hide');
            }
            if (this_data.cbprev !== ''){
                tileStoreLinks.attr('href',  this_data.link );
                tileStoreLinks.attr('data-navigation-id', this_data.sourceId);
                obj.find('.fav-now').text(this_data.cb);
                obj.find('.fav-was').removeClass('hide').text(this_data.cbprev);
            } else {
                tileStoreLinks.attr('href',  this_data.link );
                tileStoreLinks.attr('data-navigation-id', this_data.sourceId);
                obj.find('.fav-now').addClass('lh-lg2').text(this_data.cb);
            }
            if (is_not_lazy){
                var img_obj = obj.find('img.lazy'),
                    img_src = img_obj.attr('data-original');
                obj.find('img.lazy').attr('src', img_src).removeClass('lazy');
            }
            this_data.is_doublecashback ? obj.addClass('is_doublecashback') : false;

            return obj;
        };

        _EbatesMobile.displayFavorites = function(options){
            var defaults = {
                    doublecashback: false,
                    recommended: false,
                    source_id: '',
                    lz_limit: 0,
                    data_click_category: ''
                },
                request,
                req_uri = '/ajax/mobile_web/ajax_favorites_data.htm?',
                source_id_param,
                lz_count = 0,
                createDoubleCashBackRows = function(a){
                    var section = $('.fav-section-doublecashback'),
                        title = a.length + ' Favorite Store';

                    section.remove('.store-highlight-box');
                    if (a.length > 0){
                        title += a.length > 1 ? 's are Double Cash Back Today' : ' is Double Cash Back Today';
                        if (document.body.classList.contains('r4')){
                            title = title.replace(' Today', '');
                        }
                        section.find('.fav-section-doublecashback-title').text(title);
                        $(a).each(function () {
                            var entity;
                            if (lz_count < defaults.lz_limit){
                                entity = _EbatesMobile.createStoreEntity(this, true, true, true);
                                lz_count++;
                            } else {
                                entity = _EbatesMobile.createStoreEntity(this, true, true);
                            }
                            section.append(entity);
                        });
                        section.removeClass('hide');
                        section.find('img.lazy').lazyload();
                    } else {
                        section.addClass('hide');
                    }
                    setTimeout(() => ebates.mw.UI.scrollingContainer.trigger('scroll'), 0);
                },

                createNotDoubleCashBackRows = function(a){
                    var section = $('.favorite-stores-wrapper');

                    section.remove('.store-highlight-box');
                    if (a.length > 0){
                        $(a).each(function () {
                            var entity;
                            if (lz_count < defaults.lz_limit){
                                entity = _EbatesMobile.createStoreEntity(this, false, true, true);
                                lz_count++;
                            } else {
                                entity = _EbatesMobile.createStoreEntity(this, false, true);
                            }
                            section.append(entity);
                        });
                        section.removeClass('hide');
                        section.find('img.lazy').lazyload();
                    } else {
                        section.addClass('hide');
                    }
                    setTimeout(() => ebates.mw.UI.scrollingContainer.trigger('scroll'), 0);
                },

                createRecommendedRows = function(a, b){
                    var section = $('.fav-section-recommended'),
                        c = a.concat(b),
                        stores,
                        max = 16;

                    section.remove('.store-highlight-box');
                    if (c.length > 0){
                        stores = c.length > max ? c.splice(0, max) : c;
                        $(stores).each(function () {
                            var entity;
                            if (lz_count < defaults.lz_limit){
                                entity = _EbatesMobile.createStoreEntity(this, false, false, true, 'Click recommended-favorite stores');
                                lz_count++;
                            } else {
                                entity = _EbatesMobile.createStoreEntity(this, false, false, false, 'Click recommended-favorite stores');
                            }
                            section.append(entity);
                        });
                        section.removeClass('hide');
                        section.find('img.lazy').lazyload();
                    } else {
                        section.addClass('hide');
                    }
                    setTimeout(() => ebates.mw.UI.scrollingContainer.trigger('scroll'), 0);
                };
            if (typeof options === 'object') {
                defaults = $.extend(defaults, options);
            }
            source_id_param = '&source_id=' + defaults.source_id;
            req_uri += defaults.doublecashback === true ? 'doublecashback=view' : '';
            req_uri += defaults.recommended === true ? '&recommended=view' : '';
            req_uri += defaults.source_id !== '' ? source_id_param : '';
            request = $.get(req_uri);
            request.done(function (response, textStatus, jqXHR) {
                if (jqXHR.getResponseHeader('VerifyMember') === '/myaccount/verify.htm'){
                    jqXHR.getResponseHeader('VerifyMember') !== null ? sessionExpiredRedirect(jqXHR.getResponseHeader('VerifyMember')) : jQuery.noop;
                } else {
                    createDoubleCashBackRows(response.data.favDoubleCashBackStores);
                    createNotDoubleCashBackRows(response.data.favNotDoubleCashBackStores);
                    createRecommendedRows(response.data.topStores, response.data.latestShoppingTripStores);
                    $('.favorites-container').addClass('is_loaded');
                    _EbatesMobile.favorites = response;
                }
                $('.favorite-stores-wrapper .ajax-loading-row').addClass('hide');
                $doc.trigger('favorites-loaded');
            });
        };

        _EbatesMobile.favorites = {};
        var csrfval = $('#_csrf').val();

        _EbatesMobile.addFavorite = function(elem, merchantId){
            const safeDelay = 200;

            if (elem.hasClass('active')){
                $.ajax({
                    url: '/my/removeFavorites.do',
                    data: 'action=delete&sup=sup_landing_merchant&cparent=true&deletefromfav=' + merchantId + '&_csrf=' + csrfval,
                    type: 'POST',
                    dataType: 'json'
                }).done(function(){
                    _EbatesMobile.favorites.data.favIds = $.grep(_EbatesMobile.favorites.data.favIds, function(value) {
                        return value != merchantId;
                    });
                });
                var store_box = elem.closest('.store-highlight-box'),
                    section = store_box.parent();
                if (section.hasClass('fav-section-doublecashback') || section.hasClass('favorite-stores-wrapper')){
                    if ($.inArray(merchantId, _EbatesMobile.favorites.data.recommendedStores) > -1) {
                        //if was from recommended, stick back in list
                        var store_box_clone = store_box.clone(true);
                        if (store_box_clone.hasClass('store-highlight-box-stacked')){
                            store_box_clone.removeClass('store-highlight-box-stacked');
                        }
                        $('.fav-section-recommended').append(store_box_clone);
                        store_box_clone.find('img.lazy').lazyload();
                    }
                    store_box.fadeOut(function(){
                        $(this).remove();
                        var dcb_fav_wrapper = $('.fav-section-doublecashback'),
                            dcb_count = dcb_fav_wrapper.find('.store-highlight-box').length,
                            title = dcb_count + ' Favorite Store',
                            reg_fav_wrapper = $('.favorite-stores-wrapper'),
                            reg_count = reg_fav_wrapper.find('.store-highlight-box').length,
                            rec_fav_wrapper = $('.fav-section-recommended'),
                            rec_count = rec_fav_wrapper.find('.store-highlight-box').length;
                        if (dcb_count > 0){
                            title += dcb_count > 1 ? 's are Double Cash Back Today' : ' is Double Cash Back Today';
                            dcb_fav_wrapper.find('.fav-section-doublecashback-title').text(title);
                        } else {
                            dcb_fav_wrapper.addClass('hide');
                        }
                        if (reg_count < 1){
                            reg_fav_wrapper.addClass('hide');
                        }
                        if (rec_count > 0){
                            rec_fav_wrapper.removeClass('hide');
                        }
                        if ((dcb_count + reg_count) < 1){
                            $('.fav-section-nofavorites').removeClass('hide');
                        }
                    });

                    //check all other tabs and remove favorite
                    setTimeout(function(){
                        $('.fav-btn[data-merch="' + merchantId + '"]')
                            .removeClass('active  fave-action-in-progress')
                            .attr('data-amp-evt-type', 'Add Favorite');
                    }, safeDelay); // postpone this class removal as the stats trigger needs it
                    $('.fav-btn[data-merch="' + merchantId + '"]').find('i').removeClass('fa-heart').addClass('fa-heart-o').removeClass('triggered');
                } else {
                    setTimeout(function(){
                        elem.removeClass('active fave-action-in-progress')
                            .attr('data-amp-evt-type', 'Add Favorite');
                    }, safeDelay); // postpone this class removal as the stats trigger needs it
                    elem.find('i').removeClass('fa-heart').addClass('fa-heart-o').removeClass('triggered');
                }

                // trigger scroll to lazyload images that may have entered the viewport
                setTimeout(() => ebates.mw.UI.scrollingContainer.trigger('scroll'), safeDelay * 3);
            } else {
                $.ajax({
                    url: '/my/addFavorites.do',
                    data: 'action=add&sup=sup_landing_merchant&cparent=true&addtofav=' + merchantId + '&_csrf=' + csrfval,
                    type: 'POST',
                    dataType: 'json'
                }).done(function(){
                    _EbatesMobile.favorites.data.favIds.push(merchantId);
                });
                setTimeout(function () {
                    elem.addClass('active')
                        .attr('data-amp-evt-type', 'Remove Favorite');
                }, 200); // postpone this class removal as the stats trigger needs it
                elem.find('i').removeClass('fa-heart-o').addClass('fa-heart').addClass('triggered');

                var favIcon = elem.find('i');
                setTimeout(function () {
                    favIcon.removeClass('triggered');
                }, 500); //remove triggered class after allowing for css animation.

                var store_box = elem.closest('.store-highlight-box'),
                    store_box_clone = store_box.clone(true);
                store_box_clone.find('.fav-btn')
                    .attr('data-amp-evt-type', 'Remove Favorite')
                    .addClass('active')
                    .removeClass('fave-action-in-progress');
                if (store_box_clone.hasClass('is_doublecashback')) {
                    //if double cash back, place in list
                    store_box_clone.addClass('store-highlight-box-stacked');
                    $('.fav-section-doublecashback').append(store_box_clone);
                } else {
                    //if not double cash back, place in general favorites list
                    $('.favorite-stores-wrapper').append(store_box_clone);
                }
                store_box_clone.find('img.lazy').lazyload();
                store_box.fadeOut(function(){
                    $(this).remove();
                    var dcb_fav_wrapper = $('.fav-section-doublecashback'),
                        dcb_count = dcb_fav_wrapper.find('.store-highlight-box').length,
                        title = dcb_count + ' Favorite Store',
                        reg_fav_wrapper = $('.favorite-stores-wrapper'),
                        reg_count = reg_fav_wrapper.find('.store-highlight-box').length,
                        rec_fav_wrapper = $('.fav-section-recommended'),
                        rec_count = rec_fav_wrapper.find('.store-highlight-box').length;
                    if (dcb_count > 0){
                        title += dcb_count > 1 ? 's are Double Cash Back Today' : ' is Double Cash Back Today';
                        dcb_fav_wrapper.find('.fav-section-doublecashback-title').text(title);
                        dcb_fav_wrapper.removeClass('hide');
                    }
                    if (reg_count > 0){
                        reg_fav_wrapper.removeClass('hide');
                    }
                    if (rec_count < 1){
                        rec_fav_wrapper.addClass('hide');
                    }
                    if ((dcb_count + reg_count) > 0){
                        $('.fav-section-nofavorites').addClass('hide');
                    }
                });
                setTimeout(() => ebates.mw.UI.scrollingContainer.trigger('scroll'), safeDelay);
            }
        };

        _EbatesMobile.addFavoriteOnly = function(merchantId, sourceId){
            var request,
                req_uri = '/ajax/mobile_web/ajax_merchant_data.htm?merchant_id=' + merchantId,
                store_box;
            if (sourceId){
                req_uri += '&source_id=' + sourceId;
            }
            $.ajax({
                url: '/my/addFavorites.do',
                data: 'action=add&sup=sup_landing_merchant&cparent=true&addtofav=' + merchantId + '&_csrf=' + csrfval,
                type: 'POST',
                dataType: 'json'
            }).done(function(){
                setTimeout(function () {
                    $(document).trigger('favorites-loaded');
                    $('#fav_clearsearch_btn').fadeOut();
                }, 100);
                _EbatesMobile.favorites.data.favIds.push(merchantId);
                request = $.get(req_uri);
                request.done(function (response, textStatus, jqXHR) {
                    if (jqXHR.getResponseHeader('VerifyMember') === '/myaccount/verify.htm'){
                        jqXHR.getResponseHeader('VerifyMember') !== null ? sessionExpiredRedirect(jqXHR.getResponseHeader('VerifyMember')) : jQuery.noop;
                    } else {
                        var response = JSON.parse(response);
                        if (response.data.merchant.is_doublecashback) {
                            //if double cash back, place in list
                            store_box = _EbatesMobile.createStoreEntity(response.data.merchant, true, true);
                            $('.fav-section-doublecashback').append(store_box);
                        } else {
                            //if not double cash back, place in general favorites list
                            store_box = _EbatesMobile.createStoreEntity(response.data.merchant, false, true);
                            $('.favorite-stores-wrapper').append(store_box);
                        }
                        store_box.find('.fav-btn').addClass('active');
                        store_box.find('img.lazy').lazyload();

                        var dcb_fav_wrapper = $('.fav-section-doublecashback'),
                            dcb_count = dcb_fav_wrapper.find('.store-highlight-box').length,
                            title = dcb_count + ' Favorite Store',
                            reg_fav_wrapper = $('.favorite-stores-wrapper'),
                            reg_count = reg_fav_wrapper.find('.store-highlight-box').length,
                            rec_fav_wrapper = $('.fav-section-recommended'),
                            rec_count = rec_fav_wrapper.find('.store-highlight-box').length;

                        if (dcb_count > 0){
                            title += dcb_count > 1 ? 's are Double Cash Back Today' : ' is Double Cash Back Today';
                            dcb_fav_wrapper.find('.fav-section-doublecashback-title').text(title);
                            dcb_fav_wrapper.removeClass('hide');
                        }
                        if (reg_count > 0){
                            reg_fav_wrapper.removeClass('hide');
                        }
                        if (rec_count < 1){
                            rec_fav_wrapper.addClass('hide');
                        }
                        if ((dcb_count + reg_count) > 0){
                            $('.fav-section-nofavorites').addClass('hide');
                        }
                        setTimeout(() => ebates.mw.UI.scrollingContainer.trigger('scroll'), 0);
                    }
                });
            });
        };

        /** Validation **/

        _EbatesMobile.validation.emailValidate = function(emailAddress) {
            var validate = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return validate.test(emailAddress);
        };

        _EbatesMobile.signoutActions = function() {
            //remove neiman marcus cookie if present
            _EbatesMobile.deleteCookie('nm_api');
        };

        _EbatesMobile.tracking = function(){
            $('.merchantStore-box').on('click', '.shopnow_btn', function(e) {
                $('.eb-merchant-page').length < 1 ? $(document).trigger('stats', {data: ['send', 'event', 'Store Interaction', 'Shopping Trip', 'Page Url: ' + _EbatesMobile.pageURL]}) : false ;
            });
            $('.container').on('click', '.merchantCoupon-box .btn-shopnow', function(e) {
                $(document).trigger('stats', {data: ['send', 'event', 'Coupon Interaction', 'Shopping Trip', 'Page Url: ' + _EbatesMobile.pageURL]});
            });
            $('.container').on('click', '.merchantCoupon-box .store-name-logo', function(e) {
                $(document).trigger('stats', {data: ['send', 'event', 'Coupon Interaction', 'Link to Merchant Landing Page', 'Page Url: ' + _EbatesMobile.pageURL]});
            });
            $('.container').on('click', '.merchantCoupon-box .btn-couponcode', function(e) {
                $(document).trigger('stats', {data: ['send', 'event', 'Coupon Interaction', 'Click Coupon Code', 'Page Url: ' + _EbatesMobile.pageURL]});
            });
        };

        _EbatesMobile.init = function () {
            _EbatesMobile.tracking();   // TODO - remove if not used

            // global email auto-correction
            $body.one('input', '.auto-correct', () => ebates.js.require(ebates.src.emailCorrector.loader, () => ebates.loadMailCheck('.auto-correct')));

            ebates.mw.UI.modals.setup();
            ebates.mw.UI.lazy.setup();

            $('.noPaymentAddress_box_trigger').on('click', function() {
                _EbatesMobile.setCookie('addover', 'true', 30);
                _EbatesMobile.toggleNoAddressDrawer('close');

            });

            $body.on('click', '.btn-getcode', function() {
                _EbatesMobile.toggleCouponCodeDrawer(this, 'open');
                $(document).trigger('stats', {data: ['send', 'event', 'Coupon Interaction', 'Show Coupon Code', 'Page Url: ' + _EbatesMobile.pageURL]});
            });

            $body.on('click', '.btn-hidecode', function() {
                _EbatesMobile.toggleCouponCodeDrawer(this, 'close');
                $(document).trigger('stats', {data: ['send', 'event', 'Coupon Interaction', 'Hide Coupon Code', 'Page Url: ' + _EbatesMobile.pageURL]});
            });

            $body.on('click', '.click-select-range', function() {
                var ua = navigator.userAgent.toLowerCase();
                var isAndroid = ua.indexOf('android') > -1;
                if (!isAndroid) {
                    this.setSelectionRange(0, $(this).val().length);
                }
            });

            $('.tab-trigger').on('click', function() {
                var target = $(this).attr('data-tab');

                if (target && typeof target !== 'undefined') {
                    $('a[href="' + target + '"]').click();
                }
            });

            $('.trunc-trigger,.trunc-read').on('click', function() {
                _EbatesMobile.truncateToggle($(this));
            });

            $('.friendreferred-btn').on('click', function() {
                $(this).hide();
                $('#referral_email_address').slideDown(200);
            });

            // favorites
            $('#fav-search').on('input', function() {
                $('#fav_clearsearch_btn')[this.value ? 'fadeIn' : 'fadeOut']();
                this.parentNode.classList[this.value ? 'add' : 'remove']('has-input');
            });


            $('#fav_clearsearch_btn').on('click', function(e) {
                var input = $(this).attr('data-target');
                if (typeof input !== 'undefined') {
                    $(input).val('');
                    $(this).hide();
                    _EbatesMobile.favClearSearchResults();
                }
            });

            $body.on('click', '.fav-btn', function(e){
                e.preventDefault();
                if (!this.classList.contains('fave-action-in-progress')) {
                    const merchant_id = $(this).attr('data-merch');

                    this.classList.add('fave-action-in-progress');
                    if (merchant_id && _EbatesMobile.isUserLoggedIn) {
                        _EbatesMobile.addFavorite($(this), merchant_id);
                    } else {
                        _EbatesMobile.showDialogAlert('Please <a href="/auth/getLogonForm.do" class="ebates-green semibold">sign in</a> to save your favorites.');
                        setTimeout(() => this.classList.remove('fave-action-in-progress'), 0);
                    }
                }
            });

            $body.on('click', '.favorite-btn', function(e) {
                e.preventDefault(); // Merchant Page
                var merchantId = $(this).attr('data-merch'),
                    favorite_btn = $(this);
                if (merchantId && _EbatesMobile.isUserLoggedIn) {
                    var category = $(this).closest('.store-banner').hasClass('merchant-landing-page') ? true : 'Store Interaction',
                        label = 'Page Url: ' + _EbatesMobile.pageURL;
                    if ($(this).hasClass('active')) {
                        $.get('/my/removeFavorites.do', {action: 'delete', sup: 'sup_landing_merchant', cparent: 'true', deletefromfav: merchantId, _csrf: csrfval}, 'json').done(function(r) {
                            if (r.success === 'true'){
                                favorite_btn.removeClass('active').find('i').removeClass('fa-heart').addClass('fa-heart-o').removeClass('triggered');
                                category ? $(document).trigger('stats', {data: ['send', 'event', category, 'Remove Favorite', label]}) : false;
                            }
                        });
                    } else {
                        $.get('/my/addFavorites.do', {action: 'add', sup: 'sup_landing_merchant', cparent: 'true', addtofav: merchantId, _csrf: csrfval}, 'json').done(function(r){
                            if (r.success === 'true'){
                                favorite_btn.addClass('active').find('i').removeClass('fa-heart-o').addClass('fa-heart').addClass('triggered');
                                var favIcon = favorite_btn.find('i');
                                setTimeout(function(){
                                    favIcon.removeClass('triggered');
                                }, 500); //remove triggered class after allowing for css animation.
                                category ? $(document).trigger('stats', {data: ['send', 'event', category, 'Add Favorite', label]}) : false;
                            }
                        });
                    }
                } else {
                    _EbatesMobile.showDialogAlert('Please <a href="/auth/getLogonForm.do" class="ebates-green semibold">sign in</a> to save your favorites.');
                    setTimeout(function () {
                        var modalHeight = $('.modal-dialog.dialog-centered').height();
                        $('.modal-dialog.dialog-centered').css('top', window.innerHeight / 2 - modalHeight / 2 + 'px');
                    }, 200);//Wait for modal to show up and calculate the height
                }
                return false;
            });

            // anchor scroll binding
            $('.anchorScroll').on('click', function(e) {
                const href = $(this).attr('href');

                e.preventDefault();
                href && _EbatesMobile.anchorScrollTo(href.replace('#', ''));
            });

            $body.on('click', '.fav_search_suggestion_row .store_link', function(e){
                e.preventDefault();
                if (!this.classList.contains('fave-action-in-progress')) {
                    this.classList.add('fave-action-in-progress');
                    $('input#fav-search').val('');
                    $('.fav_search_suggestion_row').hide();
                    var merchantId = $(this).attr('data-merchantid'),
                        is_fav = false;
                    if ($.inArray(merchantId, _EbatesMobile.favorites.data.favIds) > -1) {
                        is_fav = true;
                    }
                    if (merchantId && _EbatesMobile.isUserLoggedIn) {
                        if (!is_fav) {
                            $('.fav-section-recommended .fav-btn[data-merch="' + merchantId + '"]').each(function () {
                                $(this).closest('.store-highlight-box').remove();
                            });
                            _EbatesMobile.addFavoriteOnly(merchantId, '23060');
                        } else {
                            _EbatesMobile.showDialogAlert('This store was already saved to your favorites.');
                        }
                    } else {
                        _EbatesMobile.showDialogAlert('Please <a href="/auth/getLogonForm.do" class="ebates-green semibold">sign in</a> to save your favorites.');
                    }
                }
            });

            if ($('.ddfavs.hide').length > 0){
                $('.ddfavs').removeClass('hide').addClass('rowpad-bottom');
            }

            // Redirect all AJAX calls if session expired
            $(document).ajaxComplete(function(e, xhr) {
                var verificationUrl = xhr.getResponseHeader('VerifyMember');
                verificationUrl && _EbatesMobile.sessionExpiredRedirect(ebates.param.add('targetpage', window.location.pathname, verificationUrl));
            });
            $(document).ajaxError(function(e, r) {
                if (r.status === 403 && r.responseText && (r.responseText.indexOf('ERR1900') >= 0 || r.responseText.indexOf('ERR1901') >= 0 || r.responseText.indexOf('ERR1902') >= 0)) {
                    window.location.href = '/signUp.do';
                    return;
                }

                var pathnameIdentify = /(\/signUp\.do|\/auth\/)/.test(window.location.pathname),
                    homeIdentify = /^(|\/)(|index\.htm|index\.do|index\.jsp|growth\/p135c\.htm)$/.test(window.location.pathname);
                    // TODO - revision of this is needed. This is not 100% logically sound...
                    // meantime, adding means to avoid this redirection to '/signUp.do'
                    // r.status && document.dispatchEvent(new Event(`ajax-${r.status}`));
                    // if (document.body.dataset.authErrorRedirect !== 'false') {
                if (r.status === 403 && ( r.responseText.indexOf('csrf_validation_failed') >= 0  )) ; else if (r.status === 403 && pathnameIdentify ){
                    window.location.href = '/signUp.do';
                } else if (r.status === 403 && homeIdentify ) {
                    try {
                        window.localStorage.setItem('timed_out', '1');
                    } catch (e) {
                        return false;
                    }
                    window.location.reload();
                } else if (r.status === 403 && /\/editUserInfos\.do/.test(window.location.pathname) ) {
                    window.location.reload();
                } else if (r.status === 403 || r.status === 302 && /\/rf\.do/.test(window.location.pathname)){
                    window.location.href = '/signUp.do';
                }
                //}
            });

            /** Jquery Lazy Loading Init **/
            $('img.lazy').lazyload({
                effect: 'fadeIn',
                threshold: 200
            });

            $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
                $('img.lazy').lazyload('update');  //bind tab change event to lazy load.

                var action = $(e.target).attr('href'),
                    label = 'Page Url: ' + window.location.pathname;
                $(document).trigger('stats', {data: ['send', 'event', 'Navigation Tabs', action, label]});
            });
            /****************************/


            $('.desktop-btn').on('click', function(e) {
                e.preventDefault();

                var request = $.ajax({
                    url: '/mobile_web/ajax/ajax_session_mobiledesktop.vm?set=desktop',
                    type: 'post'
                });

                request.done(function (response, textStatus, jqXHR){
                    if (response.trim().toLowerCase() == 'success') {
                        location.reload();
                    }
                });
                $(document).trigger('stats', {data: ['send', 'event', 'Switch Platform', 'Change to Desktop View', 'Page Url: ' + _EbatesMobile.pageURL]});
            });


            $('.carousel').on('swipeleft', function() {
                $(this).carousel('next');
            })
                .on('swiperight', function() {
                    $(this).carousel('prev');
                });

            _EbatesMobile.secondaryTabs();
        };

        $.fn.extend({
            ebatesAddScrollTop: () => {},   // remaining references to be removed separately

            indicateLoading: function(options) {

                var defaults = {
                    disable: true,
                    action: 'show',
                    icon: '//static.ebates.com/mobile_web/_/css/images/ajax-loader.gif'
                };


                if (typeof options === 'object') {
                    var options = $.extend(defaults, options);
                } else {
                    var options = $.extend(defaults, {action: options});
                }

                if (typeof options === 'object') {

                    if (options.action === 'show') {
                        $(this).prepend('<img src="' + options.icon + '" style="height:1em" class="indicateLoading" />');
                        if (options.disable) {
                            $(this).attr('disabled', 'disabled');
                        }
                    } else if (options.action === 'hide') {

                        $(this).find('.indicateLoading').remove();
                        $(this).removeAttr('disabled');
                    }
                }
            }
        });

        ebates.mw.UI.sticky = (element, top, ops = {}) => {
            const classList = element.classList,
                parent = element.parentNode,
                next = element.nextSibling,
                { triggeringOffset = 0, positioningOffset = 0, throttlingInterval = 50 } = ops,
                scrollHandler = () => {
                    if (scrollingContainer.scrollTop >= 0 ) {    // if not rubber-band page over-scroll
                        const placeHolderTop = parent.getBoundingClientRect().top,
                            ceiling = top.getBoundingClientRect().bottom,
                            hitCeiling = (placeHolderTop - triggeringOffset) < ceiling;

                        if (hitCeiling && !classList.contains('reached-top')) { // the element hit ceiling
                            classList.add('fixed', 'reached-top');
                            classList.remove('absolute');
                            element.style.top = `${ceiling + positioningOffset}px`;
                            document.body.appendChild(element); // a hack to make sure the fixed element is not jittery on iOS
                            $(element).trigger('sticky-mode-on');
                        } else if (!hitCeiling && !classList.contains('absolute')) {
                            classList.add('absolute');
                            classList.remove('fixed', 'reached-top');
                            element.style.top = 0;
                            parent.insertBefore(element, next);
                            $(element).trigger('sticky-mode-off');
                        }
                    }
                },
                scrollId = ebates.UI.scroll.add(scrollHandler, ebates.mw.UI.scrollingContainer[0], throttlingInterval);

            // "ui-reset" is a general event to communicate major UI changes, such as showing Branch banner, inserting count-down timers and so on
            $doc.on('ui-reset', () => {
                const ceiling = top.getBoundingClientRect().bottom;

                classList.remove('reached-top');
                if (!classList.contains('absolute')) {
                    element.style.top = `${ceiling + positioningOffset}px`;
                }
            });

            return scrollId; // returns scroll id
        };

        // iOS scrolling lock hack
        ebates.mw.UI.iosFreezingScrollHack = container => {
            const hackHandler = function () {
                if (this.offsetHeight + 5 < this.scrollHeight) {
                    if (this.scrollTop === 0) {
                        this.scrollTop++;
                    } else if (this.scrollTop + this.offsetHeight === this.scrollHeight) {
                        this.scrollTop--;
                    }
                }
            };

            container.addEventListener('scroll', hackHandler);

            // remove this hack when we have a live bootstrap carousel - it does not play well with it
            $doc.on('slide.bs.carousel', () => container.removeEventListener('scroll', hackHandler));
        };

        if (ebates.mw.isIOS) {
            document.body.classList.add('ios-device');
            ebates.mw.UI.iosFreezingScrollHack(scrollingContainer);
        }

        $(() => {
            _EbatesMobile.init();
            // --- Global overlay loader --- //
            ebates.overlays = ebates.overlays || {};
            if (ebates.overlays.activate && Array.isArray(ebates.overlays.activate.options) && ebates.overlays.activate.options.length){
                ebates.js.require(ebates.src.modal, function() {
                    setTimeout(function(){
                        if ( !(ebates.UI && ebates.UI.active && ebates.UI.active.modal) ) {
                            ebates.UI.modal.apply(ebates.UI, ebates.overlays.activate.options);
                        }
                    }, 0);
                });
            }
        });

        $body.on('click', '.forgot-pwd-modal-link', function(){
            ebates.css.require(ebates.src.css.mwAccountRecovery);
            ebates.js.require(ebates.src.accountRecovery, function() {
                $(document).trigger("loadForgotPasswordModal");
            });
            $doc.trigger("stats", {
                data: ["send", "event", 'Account Recovery', 'Click - Forgot Password Link', 'Forgot Password - Open email modal'],
                map: {external: {ec: 'Account Recovery', ea: 'Click - Forgot Password Link', el: 'Forgot Password - Open email modal'}}
            });
        });

        if(ebates.param.get('social_media') === 'APL' && window.opener && window.opener.location.host === location.host){
            window.name = 'Rakuten-AppleSignInWindow';
            var tempWindow = window.opener.open('/',window.name,'height=500,width=600,left=200,top=100,resizable=yes,scrollbars=yes');
            var tempUrl = ebates.param.add('type',ebates.param.get('type'),window.opener.location.href);
            tempUrl = ebates.param.add('social_media',ebates.param.get('social_media'),tempUrl);
            window.opener.location.assign(tempUrl);
            tempWindow.close();
        }

        // setting cookie to send event in amplitude when user sign up from Referral
        var eeidParam = ebates.param && ebates.param.get('eeid');
        if(eeidParam) {
            ebates.cookie.set('eeid', eeidParam);
        }
    })($('body'), $(document));

})();
